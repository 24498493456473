import { Subscription, DeleteBanner, TrialBanner, AlertBanner } from "@homefile/components";
import { useSubscriptionData } from "../../../hooks/useSubscriptionData";
import { useAppDispatch } from "../../../app/hooks";
import { setDrawer } from "../../../features/drawer/drawerSlice";
import { ActiveHomeI } from "@homefile/components/dist/interfaces";
import { ReactNode, useState, useEffect } from 'react'
import { Center } from "@chakra-ui/react";
import BeatLoader from "react-spinners/BeatLoader";


interface SubscriptionModuleI {
    handleDelete: (home: ActiveHomeI) => void
    onLaunchpad: boolean
    homeStorage: number
    deletedLength: number
    deleteLoading: boolean
}

const SubscriptionModule = ({handleDelete, onLaunchpad, homeStorage, deletedLength, deleteLoading}: SubscriptionModuleI) => {
    const dispatch = useAppDispatch()
    const [banner, updateBanner]= useState<ReactNode>(<></>)
    const {
        homes,
        totalStorage,
        usedStorage,
        state,
        currentDay,
        singleHomeStorage,
        availableStorage,
        loading
    } = useSubscriptionData(homeStorage)
    const [loadingState, updateLoadingState] = useState(true)

    const openProfile = () => {
        dispatch(setDrawer({ location: "right", content:'Profile', size: "md", data: {defaultTab: 1} }))
    }

    const handleViewDelete = () => {
        dispatch(setDrawer({ location: "right", content:'DeleteHomesItems', size: "md", data: {deleteHomes: onLaunchpad} }))
    }

    const menuItems = [{
        label: 'Manage Account',
        handleClick: openProfile,
    }]

    useEffect(() => {
        if (state === "trial" && currentDay <= 7) {
            updateBanner(<TrialBanner totalDays={7} currentDay={currentDay} buttonLabel="Subscribe" buttonAction={openProfile}/>)
        } else if (state === "running-out") {
            updateBanner(<AlertBanner buttonLabel="Account" buttonAction={openProfile} />)
        } else if (deletedLength > 0) {
            if (onLaunchpad) {
                updateBanner(<DeleteBanner beDeleted={deletedLength > 1 ? `${deletedLength} homes have` : `${deletedLength} home has`} buttonAction={handleViewDelete} />)
            } else {
                updateBanner(<DeleteBanner beDeleted={deletedLength > 1 ? `${deletedLength} items have` : `${deletedLength} item has`} buttonAction={handleViewDelete} />)
            }
        } else {
            updateBanner(<></>)
        }

        if (!loading && !deleteLoading) {
            updateLoadingState(false)
        }

    }, [state, deletedLength, loading, deleteLoading])

    return (
        <> 
            {
                loadingState ? (
                    <Center h="4rem" bg="white">
                                <BeatLoader color="gray" size={8} />
                            </Center>
                ) : (
                    onLaunchpad ? (
                        <Subscription 
                            banner={banner}
                            disabled={false}
                            homes={homes}
                            menuItems={menuItems}
                            totalStorage={totalStorage}
                            onDeleteClick={handleDelete}
                            availableStorage={availableStorage}
                            state={state}
                            totalUsed={usedStorage}
                        />
                    ) : (
                        <Subscription 
                            banner={banner}
                            disabled={false}
                            menuItems={menuItems}
                            totalStorage={totalStorage}
                            onDeleteClick={handleDelete}
                            state={state}
                            totalUsed={singleHomeStorage}
                            title="Storage Used"
                            availableStorage={availableStorage}
                            storageLabel="% of Total Storage Used"
                        />
                    )
                )
            }
            
        </>
    )
}

export default SubscriptionModule