import { useState, useEffect} from "react";
import { RootState } from '../app/store';
import { useAppSelector } from "../app/hooks";
import { StorageI } from "../features/auth/authSlice";
import { ActiveHomeI } from "@homefile/components/dist/interfaces";
import { useGetPaymentPlansQuery } from "../features/api";
import { useGetUserSubscriptionData } from "./useGetUserSubscriptionData";

export const useSubscriptionData = (homeStorage: number) => {
    const user = useAppSelector((state: RootState) => state.auth.user)
    const [homes, updateHomes] = useState<ActiveHomeI[]>([])
    const [totalStorage, updateTotalStorage] = useState(2)
    const [usedStorage, updateUsedStorage] = useState(0)
    const [state, updateState] = useState<'trial' | 'paid' | 'running-out'>('paid')
    const [currentDay, setCurrentDay] = useState(1)
    const {data} = useGetPaymentPlansQuery(null)
    const [plans, updatePlans] = useState([] as any)
    const [singleHomeStorage, updateSingleHomeStorage] = useState(0)
    const [availableStorage, updateAvailableStorage] = useState(0)
    const [loading, updateLoading] = useState(true)

    const {activeSubscription, isLoading} = useGetUserSubscriptionData(user.firstName === "" ? false : true)

    useEffect(() => {
        if (data) {
            updatePlans(data)
        }
    }, [data])

    useEffect(() => {
        if (user.storage && user.storage.length > 0) {
            let storageCalc = 0
            const mapHomes = user.storage.filter((home: StorageI) => home.active).map((home: StorageI) => {
                if (home.storage > 0) {
                    const gbTransform = Number((home.storage / 1024).toFixed(2)) > 0.01 ? Number((home.storage / 1024).toFixed(2)) : 0.01
                    storageCalc += gbTransform
                    return {name: home.name, id: home._id, totalUsed: gbTransform}
                } else {
                    return {name: home.name, id: home._id, totalUsed: home.storage}
                }
            })
            updateUsedStorage(parseFloat(storageCalc.toFixed(2)))
            updateHomes(mapHomes)
        }

        if (user && user.paymentInfo) {
            setCurrentDay(user.paymentInfo.trialDay)
        }
    }, [user])

    useEffect(() => {

        const storageFloor = Math.floor(usedStorage)
        if (storageFloor % 2 === 0) {
            updateTotalStorage(storageFloor + 2)
        } else {
            updateTotalStorage(storageFloor + 1)
        }

    }, [usedStorage])

    useEffect(() => {
        if (user && user.paymentInfo) {
            if (user.paymentInfo.trial && !activeSubscription) {
                updateState("trial")
            } else {
                if (usedStorage <= totalStorage) {
                    const percentage = (usedStorage/totalStorage) * 100
                    if (percentage > 80) {
                        updateState("running-out")
                    } else {
                        updateState("paid")
                    }
                }
            }
        }
        if (!isLoading) {
            updateLoading(false)
        }
    }, [totalStorage, usedStorage, user, activeSubscription, isLoading])

    useEffect(() => {
        if (homeStorage > 0) {
            const gbTransform = Number((homeStorage / 1024).toFixed(2)) > 0.01 ? Number((homeStorage / 1024).toFixed(2)) : 0.01
            updateSingleHomeStorage(gbTransform)
        }
    }, [homeStorage])
    

    useEffect(() => {
        updateAvailableStorage(Math.round((totalStorage - usedStorage) * 100) / 100)

    }, [totalStorage, usedStorage])

    return {
        homes,
        totalStorage,
        usedStorage,
        state,
        currentDay,
        plans,
        singleHomeStorage,
        availableStorage,
        loading
    }
} 
