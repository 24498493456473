import { useEffect, useState } from "react";
import { ContactsContent } from "@homefile/components";
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { useGetProgressReportsQuery, useUpdateProgressReportMutation, useDeleteReportMutation, useGetItemSubTypesByItemTypeQuery } from "../../../features/api";
import { RootState } from "../../../app/store";
import { InfoContactCardI } from "@homefile/components/dist/interfaces";
import BeatLoader from "react-spinners/BeatLoader";
import { Center } from "@chakra-ui/react";
import GenericDialog from "../../Dialogs/GenericDialog";
import { ResponseType } from "../../../interfaces/DataInterfaces";
import { useDisclosure, Box } from "@chakra-ui/react";
import { useGetDefaultConfig } from "../../../hooks/useGetDefaultConfig";
import CreateItemDrawer from '../../Drawers/CreateItem'
import {useFileDetails} from '../../../hooks/useFileDetails'

const ContactListDrawer = () => {
    const [contacts, updateContacts] = useState<InfoContactCardI[]>([])
    const dispatch = useAppDispatch();
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const userType = useAppSelector((state: RootState) => state.auth.homeAssociationType)
    const [selectedContactId, updateSelectedContactId] = useState("")
    const [openModal, updateOpenModal] = useState(false)
    const [deleteSelectedContact] = useDeleteReportMutation()
    const handleClose = () => {
        dispatch(clearDrawer());
    }
    const [modalHeader, updateModalHeader] = useState("Remove Contact from Home")
    const [modalBody, updateModalBody] = useState("Are you sure you want to remove this contact information?")
    const { isOpen, onToggle } = useDisclosure();
    const {defaultConfig} = useGetDefaultConfig()
    const [selectedContact, updateSelectedContact] = useState({} as any)
    const [skip, updateSkip] = useState(true)
    const { data, isLoading } = useGetProgressReportsQuery({homeId, type: defaultConfig.config.contactTypeId, subType: ""}, {skip});
    const {data: contactSubTypes} = useGetItemSubTypesByItemTypeQuery(defaultConfig.config.contactTypeId, {skip})
    const [subTypes, updateSubsTypes] = useState([] as any)

    const {mapOldItem} = useFileDetails([])

    useEffect(() => {
        if (defaultConfig.config.contactTypeId !== "") {
            updateSkip(false)
        }
    }, [defaultConfig])

    useEffect(() => {
        if (contactSubTypes) {
            updateSubsTypes(contactSubTypes)
        }
    }, [contactSubTypes])

    useEffect(() => {
        if (data && subTypes.length > 0) {
            let contactList: InfoContactCardI[] = []
            data.forEach((contact: any) => {
                if (contact.report.length > 0) {
                    const reportObj: any = {}
                    contact.report.forEach((field: any) => {
                        if (field.type === "grid") {
                            field.children.forEach((child: any) => {
                                const keyValue = child.name.replace(/\s+/g, '').toLowerCase()
                                reportObj[keyValue] = child.value
                            })
                        } else {
                            const keyValue = field.name.replace(/\s+/g, '').toLowerCase()
                            reportObj[keyValue] = field.value
                        }
                    })
                    const sendCategory = subTypes.find((type: any) => type._id === contact.subType)
                    const newContact = {
                        address: reportObj.address,
                        category: sendCategory.name || "",
                        city: reportObj.city,
                        company: reportObj.company,
                        email: reportObj.email,
                        firstName: reportObj.firstname,
                        _id: contact._id,
                        lastName: reportObj.lastname,
                        phone: reportObj.phone,
                        state: reportObj.state,
                        title: contact.title,
                        zip: reportObj.zip
                    }
                    contactList = [...contactList, newContact]
                } else {
                    const newContact = {
                        address: "",
                        category: "",
                        city: "",
                        company: "",
                        email: "",
                        firstName: "",
                        _id: contact._id,
                        lastName: "",
                        phone: "",
                        state: "",
                        title: contact.title,
                        zip: ""
                    }
                    contactList = [...contactList, newContact]
                }
            })
            updateContacts(contactList)
        }
    }, [data, subTypes])

    const handleDelete = (contact: InfoContactCardI) => {
        updateSelectedContactId(contact._id)
        updateOpenModal(true)
    }

    const handleDetails = (contact: InfoContactCardI) => {
        const targetContact = data.find((tContact: any) => tContact._id === contact._id)
        if (targetContact.report.length === 10) {
            const mappedContact = mapOldItem(targetContact)
            updateSelectedContact(mappedContact)
        } else {
            updateSelectedContact(targetContact)
        }
        onToggle()
    }

    const handleCloseModal = () => {
        updateOpenModal(false)
    }

    const handleAdd = () => {
        updateSelectedContact({})
        onToggle()
    }

    const deleteContact = async () => {

        const contactRes: ResponseType = await deleteSelectedContact(selectedContactId)


        if (contactRes.data) {
            updateOpenModal(false)
        } else {
            console.log('fail', contactRes.error)
            updateModalHeader("Something went wrong.")
            updateModalBody("Please try again later.")
        }
        
    }


    const menuItems = [
        {
            label: 'Edit',
            handleClick: (contact: InfoContactCardI) => {handleDetails(contact)},
        },
        {
            label: 'Delete',
            handleClick: (contact: InfoContactCardI) => {handleDelete(contact)},
        },
    ]

    return (
        <>
            {
                isLoading ? (
                    <Center h="8rem" bg="container.primary">
                      <BeatLoader color="gray" size={8} />
                    </Center>
                  ) : (
                    <Box overflow="scroll" maxHeight="500px">
                        <ContactsContent disabled={userType === "member" ? true : false} handleAdd={handleAdd} menuItems={menuItems} contacts={contacts} handleClose={handleClose}>
                            <>
                            {
                                isOpen && (
                                    <CreateItemDrawer 
                                        open={isOpen}
                                        back={onToggle}
                                        formName="Contact"
                                        formType={defaultConfig.config !== undefined ? defaultConfig.config.contactTypeId : ""}
                                        completedForm={selectedContact}
                                        isSlider={true}
                                    />
                                )
                            }
                            </>
                        </ContactsContent>
                        <GenericDialog header={modalHeader} body={modalBody} action={deleteContact} close={handleCloseModal} openModal={openModal} actionText="Delete" cancelText="Cancel"  />
                    </Box>
                )
            }
        </>
    );
}

export default ContactListDrawer