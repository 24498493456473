import { ResponseType } from '../interfaces/DataInterfaces';
import {
    useUpdateProgressReportMutation,
  } from "../features/api";
import { useAppDispatch } from "../app/hooks"
import { setDocumentLoading } from "../features/document/documentSlice";
import {useSendDocument} from './useSendDocument';
import {openDialog} from '../features/dialog/dialogSlice'
import { useRoomSubType } from './useRoomSubType';

export interface IUpdateDocument {
    newReportId: string
    homeId: string
    reportTitle: string
    report: any
    type?: string
    subType?: string
    room?: string
}

export const useUpdateItem = (successFunction: (data?: any) => void) => {
    const dispatch = useAppDispatch();
    const [updateReport] = useUpdateProgressReportMutation();
    const {addRoomSubType} = useRoomSubType()

    const handleUpdate = async (props: IUpdateDocument) => {
        dispatch(setDocumentLoading({loading: true}))
        const {newReportId, homeId, reportTitle, report, type, subType, room } = props;
        const reportSendData = {
            id: newReportId,
            home: homeId,
            title: reportTitle,
            report: report,
            type: type,
            subType: subType,
            room: room
        };
        const updatedReportResponse: ResponseType = await updateReport(
            reportSendData
        );
        if (updatedReportResponse.data) {
            if (room !== undefined && subType !== undefined) {
                addRoomSubType({
                    room,
                    subType: subType
                })
            }
            dispatch(setDocumentLoading({loading: false}))
            successFunction(updatedReportResponse.data)
        } else {
            console.log("update fail",updatedReportResponse);
            dispatch(setDocumentLoading({loading: false}))
            dispatch(openDialog({message: updatedReportResponse.error.data.message}))
        }
    };


    return {
        handleUpdate
    }
}