import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { clearDrawer } from "../../../features/drawer/drawerSlice";
import { useGetUserReportsByFolderQuery} from "../../../features/api";
import { RootState } from "../../../app/store";
import {
    DisplayFiles,
    DisplayOptions,
    FilesUploader,
    FolderDetailBody,
    FolderDetailContent,
    FolderDetailFooter,
    FolderDetailHeader,
    FolderInfo,
    MoveModal,
    RightPanel, 
    DisplayFilesDetail,
    SharedAccounts
} from '@homefile/components';
import { useState, useEffect, ReactNode } from "react";
import { useFileUpload } from "../../../hooks/useFileUpload";
import {FolderI} from '../../../interfaces/FolderInterfaces';
import { useFileDetails } from "../../../hooks/useFileDetails";
import { useFolderDetails } from "../../../hooks/useFolderDetails";
import DocumentInfoSlider from "../../Sliders/DocumentInfoSlider";
import DeleteFileDialog from "../../Dialogs/DeleteFileDialog";
import { SelectItemI } from "@homefile/components/dist/interfaces/inputs/Select.interface";
import GenericDialog from "../../Dialogs/GenericDialog";
import { FolderTypes, FolderVariantTypes, FileInfoI, UploadFileI } from "@homefile/components/dist/interfaces";
import { useFolderSharedAccounts } from "../../../hooks/useFolderSharedAccounts";
import AddSharedAccount from "./AddSharedAccount";

const FolderDetailsDrawer = (folder: FolderI) => {
    const dispatch = useAppDispatch();
    const homeId = useAppSelector((state: RootState) => state.home.id)
    const [skip, updateSkip] = useState(true)
    const {data} = useGetUserReportsByFolderQuery({homeId, folderId: folder._id}, {skip})
    const [fileList, updateFiles] = useState<FileInfoI[]>([])
    const [dataFiles, updateDataFiles] = useState<FileInfoI[]>([])
    const {handleNewFile, fileUploading} = useFileUpload()
    const [sortLoading, updateSortLoading] = useState(false)
    const {
        handleEditFile, 
        handleFileClick, 
        handleDelete, 
        handleOpenFile, 
        userData, 
        featuredFile, 
        closeDocument, 
        isOpen, 
        deleteFileCallback, 
        deleteFileId, 
        handleCloseDeleteModal, 
        deleteModal, 
        editing, 
        onRightClose, 
        onRightOpen, 
        isRightOpen,
        firstFilterItems,
        firstInitialFilter,
        secondFilterItems,
        secondInitialFilter,
        handleSaveFolderMove,
        onFirstFilterClick,
        showModal,
        handleCloseModal,
        handleOpenModal
    } = useFileDetails(fileList)

    const handleCloseButton = () => {
        dispatch(clearDrawer())
    }
    const [currentName, setCurrentName] = useState<string>('')
    const [currentFolderType, setCurrentFolderType] = useState<FolderTypes>('private')
    const [folderHeaderIcon, updateFolderHeaderIcon] = useState<FolderVariantTypes>('system')
    const [currentType, setCurrentType] = useState<'list' | 'grid' | 'item'>('grid')
    const dateFilters = ['All', 'Newest', 'Oldest']
    const typeFilters = ['All', 'Images', 'Videos', 'Documents']

    const {
        accounts, 
        addNewUser, 
        removeExistingUser, 
        isShareOpen,
        onShareClose,
        onShareOpen,
        shareAccounts,
        shareNewUser
    } = useFolderSharedAccounts({id: folder._id, skip: skip})

    const {
        editFolderDetails, 
        deleteFolder,
        serverDelete,
        openDeleteFolderDialog,
        deleteDialogHeader,
        deleteDialogBody,
        updateOpenDeleteFolderDialog,
        canDelete,
        passFolder,
        openPrivateFolderDialog,
        updatePrivateFolderDialog,
        editPrivateFolder
    } = useFolderDetails({fileLength: fileList.length, deleteCallback: handleCloseButton, folderId: folder._id, folderData: folder})


    const placeholder: FileInfoI = {
        _id: "123",
        title: "Placeholder",
        type: "123",
        updatedAt: "2023-01-12T20:30:06.445Z"
    }

    const handleChangeType = (type: 'list' | 'grid' | 'item') => setCurrentType(type)


    useEffect(() => {
        if (!folder.fromTour) {
            updateSkip(false)
        }
        setCurrentName(passFolder.name)
        if (passFolder.icon !== "project") {
            if (passFolder.icon === "private" || passFolder.icon === "shared") {
                if (passFolder.shareable) {
                    setCurrentFolderType("shared")
                    updateFolderHeaderIcon("shared")
                } else {
                    setCurrentFolderType("private")
                    updateFolderHeaderIcon("private")
                }
            } else {
                updateFolderHeaderIcon("system")
            }
        } else {
            setCurrentFolderType("project")
            updateFolderHeaderIcon("project")
        }
    }, [passFolder, folder])

    useEffect(() => {
        
        if (data) {
            updateFiles(data.reports)
            updateDataFiles(data.reports)
        } else {
            if (folder.fromTour !== undefined && folder.fromTour === true) {
                updateFiles([placeholder])
            } else {
                updateFiles([])
            }
        }
    }, [data])


    const handleUpload = (files: UploadFileI[]) => {
        handleNewFile(files, homeId, folder._id)
    }

    const handleDateFilter = (item: SelectItemI | string) => {
        updateSortLoading(true)
        if (item === "Oldest") {
            const filesByDate = [...fileList].sort((a,b) => {
                if (a.updatedAt && b.updatedAt) {
                    return new Date(a.updatedAt).valueOf() - new Date(b.updatedAt).valueOf()
                } else {
                    return 0
                }
              });
            updateFiles(filesByDate)
            
        } else if (item === "Newest") {
            const filesByDate = [...fileList].sort((a,b) => {
                if (a.updatedAt && b.updatedAt) {
                    return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
                } else {
                    return 0
                }
              });
            updateFiles(filesByDate)
        }

        updateSortLoading(false)
    }

    const handleTypeFilter = (item: SelectItemI | string) => {

        if (item === "Images") {
            const reportArray = fileList.filter((file: FileInfoI) => file.type === "images type?")
            updateFiles(reportArray)
        } else if (item === "Videos") {
            const reportArray = fileList.filter((file: FileInfoI) => file.type === "video type?")
            updateFiles(reportArray)
        } else if (item === "Documents") {
            const reportArray = fileList.filter((file: FileInfoI) => file.type === "documents type?")
            updateFiles(reportArray)
        } else {
            updateFiles(dataFiles)
        }
    }

    const fileMenu = [
        {
          handleClick: handleFileClick,
          label: 'Edit',
        },
        {
          handleClick: handleDelete,
          label: 'Delete',
        },
    ]

    const sharedAccountsMenu = [
        {
            handleClick: removeExistingUser,
            label: "Remove"
        }
    ]

    const bodyContents: ReactNode = <>
        <FilesUploader
            displayType={currentType}
            onUpload={handleUpload}
            totalFiles={fileList.length}
            initialHeight={fileList.length > 0 ? undefined : "95%"}
            totalShowing={currentType === 'item' ? 1 : fileList.length}
            uploading={fileUploading || sortLoading}
        />
        <DisplayFiles
            files={fileList}
            menu={fileMenu}
            onClick={handleOpenFile}
            onMove={handleOpenModal}
            type={currentType}
        />
        {showModal && (
            <MoveModal
                firstFilterItems={firstFilterItems}
                firstInitialFilter={firstInitialFilter}
                onClose={handleCloseModal}
                onFirstFilterClick={onFirstFilterClick}
                onSave={handleSaveFolderMove}
                secondFilterItems={secondFilterItems}
                secondInitialFilter={secondInitialFilter}
            />
        )}
    </>

    const sharedContents: ReactNode = <>
        <SharedAccounts
            accounts={accounts}
            totalAccounts={accounts.length}
            totalShowing={accounts.length}
            onClick={() => {onShareOpen()}}
            label="Invite"
            disabled={!passFolder.shareable}
            menu={sharedAccountsMenu}
        />
    </>

    return (
        <>
            <FolderDetailContent>
                <FolderDetailHeader
                    folderName={currentName}
                    folterType={folderHeaderIcon}
                    onClose={handleCloseButton}
                >
                    {
                        !folder.public && (
                            <FolderInfo
                                folderName={currentName}
                                folderType={currentFolderType}
                                onSave={editFolderDetails}
                            />
                        )
                    }
                {(dataFiles.length > 0 || folder.fromTour) && (
                    <DisplayOptions
                        dateFilters={dateFilters}
                        initialDisplay={currentType}
                        onDisplayClick={handleChangeType}
                        onDateFilter={handleDateFilter}
                        onTypeFilter={handleTypeFilter}
                        typeFilters={typeFilters}
                    />
                )}
                </FolderDetailHeader>
                <FolderDetailBody sharedWith={sharedContents} contents={bodyContents} shareable={passFolder.shareable}/>
                <FolderDetailFooter isSystemFolder={folder.public} onDelete={deleteFolder} />
                <DocumentInfoSlider
                    homeId={homeId}
                    showForm={true}
                    editDocument={featuredFile}
                    open={isOpen}
                    back={closeDocument}
                    documentId={featuredFile._id}
                />
                <RightPanel
                    overlay={false}
                    children={
                    <DisplayFilesDetail
                        recipients={accounts}
                        isDocument={false}
                        handleEdit={handleEditFile}
                        addedBy={`${userData.firstName} ${userData.lastName}`}
                        file={featuredFile}
                        handleCloseButton={onRightClose}
                        folderIcon={currentFolderType}
                        folderTitle={currentName}
                        editing={editing}
                        shareable={passFolder.shareable}
                    />
                    }
                    isOpen={isRightOpen}
                    onClose={onRightClose}
                    size={'lg'}
                />
                <RightPanel
                    overlay={false}
                    children={
                        <AddSharedAccount 
                            handleAdd={addNewUser}
                            handleClose={onShareClose}
                            recipients={shareAccounts}
                            handleShare={shareNewUser}
                        />
                    }
                    isOpen={isShareOpen}
                    onClose={onShareClose}
                    size={'lg'}
                />
            </FolderDetailContent>
            
            <DeleteFileDialog closeModal={handleCloseDeleteModal} openModal={deleteModal} fileId={deleteFileId} deleteFile={deleteFileCallback} />
            <GenericDialog header={deleteDialogHeader} body={deleteDialogBody} action={serverDelete} close={() => {updateOpenDeleteFolderDialog(false)}} openModal={openDeleteFolderDialog} actionText={canDelete ? "Delete" : ""} cancelText={"Cancel"}  />
            <GenericDialog header={"Private Folder Update"} body={"Previous shared accounts will no longer have access to this folder."} action={editPrivateFolder} close={() => updatePrivateFolderDialog(false)} openModal={openPrivateFolderDialog} actionText={"Update"} cancelText={"Cancel"}  />
        </>
    )
}

export default FolderDetailsDrawer;