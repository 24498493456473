import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import documentReducer from '../features/document/documentSlice';
import { homefileApi } from '../features/api';
import drawerReducer from '../features/drawer/drawerSlice';
import tourReducer from '../features/tour/tourSlice';
import homeReducer from '../features/home/homeSlice';
import dialogReducer from '../features/dialog/dialogSlice';
import roomReducer from '../features/room/roomSlice';
import {combineReducers} from "redux"; 
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
    auth: authReducer,
    drawer: drawerReducer,
    home: homeReducer,
    document: documentReducer,
    dialog: dialogReducer,
    tour: tourReducer,
    room: roomReducer,
    [homefileApi.reducerPath]: homefileApi.reducer
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [homefileApi.reducerPath, 'document', 'drawer', 'dialog', 'tour', 'room']
}

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(homefileApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
