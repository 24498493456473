import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
    name: "home",
    initialState: {id: "", name: "", associatedAccounts: [], contactsLength: 0, homeStorage: 0},
    reducers: {
        setHome: (state, action) => {
            state.id = action.payload.id
            state.name = action.payload.name
        },
        setHomeAccounts: (state, action) => {
            state.associatedAccounts = action.payload.associatedAccounts
        },
        clearHomeAccounts: (state) => {
            state.associatedAccounts = []
        },
        setHomeContactsLength: (state, action) => {
            state.contactsLength = action.payload
        },
        clearHomeContactsLength: (state) => {
            state.contactsLength = 0
        },
        setHomeStorage: (state, action) => {
            state.homeStorage = action.payload
        },
        clearHomeStorage: (state) => {
            state.homeStorage = 0
        },
        clearHome: (state) => {
            state.id = ""
            state.name = ""
            state.associatedAccounts = []
            state.contactsLength = 0
            state.homeStorage = 0
        }
    }
})

export const {setHome, clearHome, setHomeAccounts, clearHomeAccounts, setHomeContactsLength, clearHomeContactsLength, setHomeStorage, clearHomeStorage } = homeSlice.actions;
export default homeSlice.reducer;