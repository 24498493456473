import {useState, useEffect} from 'react';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useGetHomeQuery, useGetProgressReportsQuery } from "../../features/api";
import FoldersModule from '../../components/Modules/FoldersModule';
import { Box, Flex, Stack, Center } from '@chakra-ui/react';
import SendReportView from "../../components/Views/SendReportView";
import {HomeHeader, HomeCardWithRecipent, HomeBoardTour, AddHomeItem, ValueMonitor, ReceiptAutofiler, TrendingValue, PropertyTaxes, HomeBoardGrid, ShortPartnerTile, AphwTile} from '@homefile/components';
import { HomeInterface } from '../../interfaces/HomeInterfaces';
import { useNavigate } from "react-router-dom";
import { setHome, setHomeAccounts, setHomeContactsLength, setHomeStorage } from "../../features/home/homeSlice";
import { setDrawer, clearDrawer } from "../../features/drawer/drawerSlice";
import BeatLoader from "react-spinners/BeatLoader";
import { useAssociatedAccount } from '../../hooks/useAssociatedAccount';
import { AssociatedAccountI } from '@homefile/components/dist/interfaces/shareHome/ShareHome.interface';
import { useFileUpload } from "../../hooks/useFileUpload";
import DeleteAssociationDialog from "../../components/Dialogs/DeleteAssociationDialog";
import { isFetchBaseQueryError} from '../../util/fetchError';
import { clearAuth } from "../../features/auth/authSlice";
import { useUpdateUserTour } from "../../hooks/useUpdateUserTour";
import { setHomeBoard } from "../../features/tour/tourSlice";
import { useCalculateHomeItemsValue } from '../../hooks/useCalculateHomeItemsValue';
import { useHomeBoardReceipts } from '../../hooks/useHomeBoardReceipts';
import { TourFolder } from '../../util/TourFolder';
import { setAssociationType } from '../../features/auth/authSlice';
import { useGetDefaultConfig } from "../../hooks/useGetDefaultConfig";
import {usePropertyInfo} from '../../hooks/usePropertyInfo';
import {usePartnerInfo} from '../../hooks/usePartnerInfo';
import {usePartnerCatalog} from '../../hooks/usePartnerCatalog'
import {ShortPartnerTileI} from '@homefile/components/dist/interfaces'

const HomeBoard = () => {
    const id = useAppSelector((state: RootState) => state.home.id)
    const bg = useAppSelector((state: RootState) => state.auth.avatarBg)
    const user = useAppSelector((state: RootState) => state.auth.user)
    const userType = useAppSelector((state: RootState) => state.auth.homeAssociationType)
    const [skipValue, updateSkip] = useState(true)
    const {data, error, isLoading} = useGetHomeQuery(id, {skip: skipValue})
    const [homeName, updateHomeName] = useState("")
    const [home, updateHome] = useState({} as HomeInterface)
    const [recipients, updateRecipients] = useState([] as AssociatedAccountI[])
    const [homeowners, updateHomeowners] = useState([] as AssociatedAccountI[])
    let navigate = useNavigate()
    const dispatch = useAppDispatch()
    const {handleNewHomeImage, fileUploading} = useFileUpload()
    const [activeTour, updateActiveTour] = useState(false)
    const {updateTour} = useUpdateUserTour()
    const homeBoardTourState = useAppSelector((state: RootState) => state.tour.homeBoardTour);
    const [homeStorage, updateHomeStorage] = useState(0)
    const {receipts} = useHomeBoardReceipts(id)
    const {defaultConfig} = useGetDefaultConfig()
    const [contactSkip, updateContactSkip] = useState(true)
    const { data: contactsData } = useGetProgressReportsQuery({homeId: id, type: defaultConfig.config.contactTypeId, subType: ""}, {skip: contactSkip});
    const {trendingInfo, taxInfo, homeCardInfo} = usePropertyInfo(id)
    const [currentStep, setCurrentStep] = useState<string>("")
    const {showMainPartnerTile, showPartnerTiles, checkHomeTiles, mainHomeTile, homeTiles} = usePartnerInfo()
    const {allPartnerData} = usePartnerCatalog({id})

    useEffect(() => {
        if (contactsData !== undefined && contactsData.length > 0) {
            dispatch(setHomeContactsLength(contactsData.length))
        } else {
            dispatch(setHomeContactsLength(0))
        }
    }, [contactsData])

    const {handleUpdateAccount, handleCloseDeleteAccountModal, handleRemoveAccount, openRemoveDialog, deleteAccountModal, associationId} = useAssociatedAccount(recipients)

    const {value} = useCalculateHomeItemsValue(id)
    
    const handleStep = (step: string) => {
        setCurrentStep(step)
        if (step === "addFiles" || step === "addName") {
            dispatch(setDrawer({ location: "right", content:'FolderDetails', size: "md", data: {...TourFolder, fromTour: true}}))
        }  else if (step === "editConnectedUser") {
            dispatch(setDrawer({ location: "right", content:'AccountList', size: "md"}))
        } else if (step === "nameFolder") {
            dispatch(setDrawer({ location: "right", content:'AddFolder', size: "md", data: "folder"}))
        } else if (step === "partnerCatalog") {
            const tileData = data.tileList ? data.tileList : []
            dispatch(setDrawer({ location: "right", content:'PartnerCatalog', size: "lg", data: tileData}))
        } else if (step === "addHouseholdItem") {
            dispatch(setDrawer({ location: "right", content:'CreateHouseholdItem', size: "md", data: null}))
        } else if (step === "shareWithOthers" || step === "createDocuments" || step === "viewContacts" || step === "viewConnected" || step === "addProject" || step === "folder") {
            dispatch(clearDrawer())
        }
    }

    const handleClose = () => {
        updateTour("homeBoard")
        dispatch(setHomeBoard({homeBoardTour: false}))
    }

    const handleAdd = () => {
        const tileData = data.tileList ? data.tileList : []
        dispatch(setDrawer({ location: "right", content:'PartnerCatalog', size: "lg", data: tileData}))
    }

    const isHomeowner = (accountTypes: string[]) => accountTypes.some((account) => account.toLowerCase() === 'homeowner')

    useEffect(() => {
        if (id.length > 0) {
            updateSkip(false)
            if (defaultConfig.config.contactTypeId !== "") {
                updateContactSkip(false)
            }
        } else {
            navigate("/dashboard")
        }
    }, [id, defaultConfig])


    useEffect(() => {
        if (user.showTour.homeBoard) {
            updateActiveTour(true)
        } else {
            updateActiveTour(false)
        }
    }, [user])

    const setAccountType = (accountTypes: string[]) => {
        if (accountTypes.some((account) => account.toLowerCase() === 'homeowner') || accountTypes.some((account) => account.toLowerCase() === 'manager')) {
            dispatch(setAssociationType("manager"))
        } else if (accountTypes.some((account) => account.toLowerCase() === 'contributor')) {
            dispatch(setAssociationType("contributor"))
        } else {
            dispatch(setAssociationType("member"))
        }
    }

    useEffect(() => {
        if (data) {
            if (data.tileList) {
                // handle showing/hiding tiles and main tile here
                checkHomeTiles(data.tileList)
            }
        }
    }, [data, user])

    useEffect(() => {
        let fetchData = true;

        if (data && fetchData) {
            updateRecipients([])
            updateHomeowners([])
            updateHomeName(data.name)
            updateHome(data)
            let homeAccounts = [] as AssociatedAccountI[];
            data.associations.forEach((association: AssociatedAccountI) => {
                if (association.user._id === user._id) {
                    setAccountType(association.accountTypes)
                }
                if (isHomeowner(association.accountTypes)) {
                    if (association.user._id === user._id) {
                        updateHomeowners((homeowners) => [...homeowners, {...association, user: {...association.user, bgColor: bg}}])
                    } else {
                        updateHomeowners((homeowners) => [...homeowners, association])
                    }
                } else {
                    updateRecipients((recipients) => [...recipients, association])
                    homeAccounts.push(association)
                }
            })
            dispatch(setHomeAccounts({associatedAccounts: homeAccounts}))
            if (data.storage > 0) {
                updateHomeStorage(data.storage)
                dispatch(setHomeStorage(data.storage))
            }
            
        } else if (isFetchBaseQueryError(error)) {
            if (error.status === 401) {
              dispatch(clearAuth())
            }
        }
        return () => {
            fetchData = false
        }
    }, [data])

    const handleEditClick = (id: string) => {
        dispatch(setHome({ id: id, name: home.name }));
        dispatch(setDrawer({ location: "right", content: "ViewHome", size: "md" }));
    }

    const addImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleNewHomeImage(e, id)
      };

    const handleAddItem = () => {
        dispatch(setDrawer({ location: "right", content:'CreateHouseholdItem', size: "md", data: null}))
    }

    const openReceipts = () => {
        dispatch(setDrawer({ location: "right", content: "ReceiptList", size: "md", data: {homes: [], onLaunchpad: false} }));
    }
    
    const handleViewDetails = () => {
        dispatch(setDrawer({ location: "right", content:'PropertyRecords', size: "md"}))
    }

    const handleAddPropertyRecords = () => {
        dispatch(setDrawer({ location: "right", content:'AddPropertyRecords', size: "md", data: home}))
    }

    const onMortgageClick = () => {
        dispatch(setDrawer({ location: "right", content:'MortgageInfo', size: "md"}))
    }

    const mortgageMenuItems =  [
        {
            label: "Edit",
            handleClick: onMortgageClick
        }
    ]

    return (
        <Box w="full" mb="4rem">
            <Stack px={[0, 'base']} spacing="base">
                <Box pt="1rem">
                    <HomeHeader buttonLabel={true ? 'Partner Catalog': undefined} disabled={userType === "member" ? true : false} onAdd={handleAdd} onBack={() => navigate("/dashboard")} homeName={homeName} />
                </Box>
                    {
                        (home.address !== undefined && !isLoading) ? 
                        <HomeBoardGrid>
                            <HomeCardWithRecipent 
                                handleEdit={() => handleEditClick(home._id)}
                                address={home.address}
                                _id={home._id}
                                image={home.image}
                                name={home.name}
                                recipients={homeowners}
                                addImage={addImage}
                                loading={fileUploading}
                                handleDeleteAccountType={openRemoveDialog}
                                handleSubmitAccountType={handleUpdateAccount}
                                disabled={userType === "member" ? true : false}
                                records={homeCardInfo}
                                onViewDetails={handleViewDetails}
                                onAddRecords={handleAddPropertyRecords}
                            />
                            <FoldersModule disabled={(userType === "member" || userType === "contributor") ? true : false} homeId={id} />
                            {
                                showMainPartnerTile && (
                                    <ShortPartnerTile 
                                        buttonText={mainHomeTile.buttonText}
                                        description={mainHomeTile.description}
                                        imageUrl={mainHomeTile.imageUrl}
                                        logo={mainHomeTile.logo}
                                        partnerName={mainHomeTile.partnerName}
                                        websiteUrl={mainHomeTile.websiteUrl}
                                        onClick={mainHomeTile.onClick}
                                        socialLinks={mainHomeTile.socialLinks}
                                        _id={mainHomeTile._id}
                                    />
                                )
                            }
                            {
                                (window.location.host === "localhost:3000" || window.location.host === "stage.homefile.cloud") && (
                                    <AphwTile />
                                )
                            }
                            <Stack spacing="base">
                                <ReceiptAutofiler 
                                    onClick={openReceipts}
                                    totalReceipts={receipts.length}
                                    filters={[]}
                                    forwardTo="homefileit@homefile.cloud"
                                    onFilter={() => {}}
                                    totalTitle="Receipts Received"
                                    />
                                <ValueMonitor 
                                    balance=""
                                    menuItems={[]}
                                    totalValue={value ? value: 0}
                                    year="2023"
                                    yearValue={value? value: 0}
                                />
                            </Stack>
                            {
                                (trendingInfo.purchasePrice || trendingInfo.marketValue) && (
                                    <TrendingValue 
                                        menuItems={mortgageMenuItems}
                                        marketValue={trendingInfo.marketValue}
                                        mortgageBalance={trendingInfo.mortgageBalance ? trendingInfo.mortgageBalance : null}
                                        purchasePrice={trendingInfo.purchasePrice}
                                        roi={trendingInfo.roi}
                                        onMortgageClick={onMortgageClick}
                                    />
                                )
                            }
                            {
                                taxInfo.taxes && (
                                    <PropertyTaxes 
                                        estimatedTaxValue={taxInfo.estimatedTaxValue}
                                        taxes={taxInfo.taxes}
                                    />
                                )
                            }
                            <Stack spacing="base">
                                <AddHomeItem disabled={userType === "member" ? true : false} handleClick={handleAddItem} />
                                <SendReportView homesLength={1} homeDisabled={userType === "member" ? true : false} />
                            </Stack>
                            {
                                showPartnerTiles && (
                                    homeTiles.map((tile: ShortPartnerTileI) => (
                                        <ShortPartnerTile 
                                            key={tile.partnerName}
                                            buttonText={tile.buttonText}
                                            description={tile.description}
                                            imageUrl={tile.imageUrl}
                                            logo={tile.logo}
                                            partnerName={tile.partnerName}
                                            websiteUrl={tile.websiteUrl}
                                            onClick={tile.onClick}
                                            socialLinks={tile.socialLinks}
                                            _id={tile._id}
                                            menuItems={tile.menuItems}
                                        />
                                    ))
                                )
                            }
                        </HomeBoardGrid> : <Center h="4rem" bg="white">
                            <BeatLoader color="gray" size={8} />
                        </Center>
                    }

                <DeleteAssociationDialog associationId={associationId} openModal={deleteAccountModal} closeModal={handleCloseDeleteAccountModal} deleteAssociation={handleRemoveAccount} />
                
            </Stack>
            <HomeBoardTour 
                isActive={activeTour || homeBoardTourState}
                handleStep={handleStep}
                handleClose={handleClose}
                currentStep={currentStep}
                meetStepUrl="https://storage.googleapis.com/homefile-videos/Homeboard_Overview_.mp4"
            />
        </Box>
    )
}

export default HomeBoard;