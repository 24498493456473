import { createSlice } from "@reduxjs/toolkit";

interface RoomStateI {
    value: number
}

const initialState: RoomStateI = {
    value: 0
}

const roomSlice = createSlice({
    name: "room",
    initialState: initialState,
    reducers: {
        addValue: (state, action) => {
            state.value += action.payload.value
        },
        clearValue: (state) => {
            state.value = 0
        }
    }
})

export const {addValue, clearValue} = roomSlice.actions;
export default roomSlice.reducer;