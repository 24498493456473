import { useAddSubTypeToRoomMutation } from '../features/api';

interface AddSubTypeI {
    room: string 
    subType: string
}

export const useRoomSubType = () => {
    const [addSubType] = useAddSubTypeToRoomMutation()

    const addRoomSubType = ({room, subType}: AddSubTypeI) => {
        addSubType({
            room: room,
            subType
        })
    }

    return {
        addRoomSubType
    }
}