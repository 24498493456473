import {useEffect, useState} from 'react'
import { 
    useGetHomePropertyInfoQuery,
    useSearchHomePropertyInfoQuery,
    useAddHomePropertyInfoMutation,
    useEditHomePropertyInfoMutation
} from "../features/api";

interface SearchPropertyI {
    house: string
    street: string
    city: string
    state: string
    zip: string
}
export const usePropertyInfo = (id: string) => {
    const [searchProperty, updateSearchProperty] = useState<SearchPropertyI>({} as SearchPropertyI)
    const [searchSkip, updateSearchSkip] = useState(true)
    const {data: searchData} = useSearchHomePropertyInfoQuery(searchProperty, {skip: searchSkip})
    const [infoSkip, updateInfoSkip] = useState(true)
    const {data: infoData} = useGetHomePropertyInfoQuery(id, {skip: infoSkip})
    const [addHomeInfo] = useAddHomePropertyInfoMutation()
    const [editHomeInfo] = useEditHomePropertyInfoMutation()
    const [propertyData, updatePropertyData] = useState({} as any)
    const [trendingInfo, updateTrendingInfo] = useState({} as any)
    const [mortgageInfo, updateMortgageInfo] = useState({} as any)
    const [taxInfo, updateTaxRecords] = useState({} as any)
    const [homeCardInfo, updateHomeCardInfo] = useState({} as any)
    const [granteeName, updateGranteeName] = useState("")
    const [saleDate, updateSaleDate] = useState("")

    const calcRoi = (estimatedValue: any, saleAmount: any) => {
        if (estimatedValue && saleAmount) {
            const roi = Math.round(((estimatedValue - saleAmount) / saleAmount) * 100)
            if (roi >= 0) {
                return `+${roi}%`
            } else {
                return `-${roi}%`
            }
        } else {
            return null
        }
    }

    useEffect(() => {
        if (id.length > 0) {
            updateInfoSkip(false)
        } else {
            updateInfoSkip(true)
        }
    }, [id])

    useEffect(() => {
        if (infoData) {
            // property records drawer
            let propData = false
            if ( infoData.propertyInfo) {
                propData = true
                const {propertyInfo, ...obj} = infoData
                const pData = {...obj, ...propertyInfo}
                updatePropertyData(pData)
            } 

            // trending value component
            let marketValue = null
            let roiTest = false 
            if (infoData.equityInfo) {
                if (infoData.equityInfo.estimatedValue) {
                    marketValue = infoData.equityInfo.estimatedValue
                }

                if (infoData.saleHistory.length > 0 && infoData.equityInfo.estimatedValue) {
                    roiTest = true
                }
            }

            let purchasePrice = 0
            if (infoData.saleHistory.length > 0) {
                if (infoData.saleHistory.length > 1) {
                    const sArray = [...infoData.saleHistory]
                    sArray.sort((a,b) => Date.parse(b.saleDate) - Date.parse(a.saleDate))
                        if (sArray[0].saleAmount) {
                            purchasePrice = sArray[0].saleAmount
                            updateSaleDate(sArray[0].saleDate)
                        }
                } else {
                    if (infoData.saleHistory[0].saleAmount) {
                        purchasePrice = infoData.saleHistory[0].saleAmount
                    }
                }
            }
            const tVal = {
                marketValue: marketValue,
                mortgageBalance: infoData.mortgagesInfo ? infoData.mortgagesInfo.estimatedMortgageBalance: null,
                purchasePrice: purchasePrice,
                roi: roiTest ? calcRoi(infoData.equityInfo.estimatedValue, purchasePrice) : null
            }
            updateTrendingInfo(tVal)

            // mortgage data
            let mortgages = false
            if (infoData.mortgages.length > 0) {
                mortgages = true
            }

            let mVal = {
                amount: 0,
                maturityDate: "",
                documentDate: "",
                estimatedMortgageBalance: infoData.mortgagesInfo ? infoData.mortgagesInfo.estimatedMortgageBalance: 0,
                estimatedMortgagePayment: infoData.mortgagesInfo ? infoData.mortgagesInfo.estimatedMortgagePayment : 0,
                interestRate: "",
                interestRateType: "",
                lenderName: "",
                purchasePrice
            }

            let mortgageData = {
                amount: 0,
                maturityDate: "",
                documentDate: "",
                interestRate: "",
                interestRateType: "",
                lenderName: "",
                granteeName: ""
            }

            if (infoData.mortgages !== undefined) {
                if (infoData.mortgages.length > 0) {
                    if (infoData.mortgages.length > 1) {
                        const mArray = [...infoData.mortgages]
                        mArray.sort((a,b) => Date.parse(b.documentDate) - Date.parse(a.documentDate))
                        mortgageData = {...mArray[0]}
                        updateGranteeName(mortgageData.granteeName)
                    } else {
                        mortgageData = {...infoData.mortgages[0]}
                    }
                }
            } 

            if (mortgages) {
                mVal = {
                    amount: mortgageData.amount,
                    maturityDate: mortgageData.maturityDate,
                    documentDate: mortgageData.documentDate,
                    estimatedMortgageBalance: infoData.mortgagesInfo ? infoData.mortgagesInfo.estimatedMortgageBalance: 0,
                    estimatedMortgagePayment: infoData.mortgagesInfo ? infoData.mortgagesInfo.estimatedMortgagePayment : 0,
                    interestRate: mortgageData.interestRate,
                    interestRateType: mortgageData.interestRateType,
                    lenderName: mortgageData.lenderName,
                    purchasePrice
                }
            } 
            updateMortgageInfo(mVal)

            // property taxes component
            let taxes = false
            if (infoData.tax !== undefined) {
                taxes = true
            }

            let taxVal = {
                estimatedTaxValue: 0,
                taxes: [{
                    taxValue: 0,
                    year: "",
                    value: 0
                }],
                title: "Estimated Property Taxes"
            }

            if (taxes) {
                taxVal = {
                    estimatedTaxValue: infoData.tax.taxAmount ? infoData.tax.taxAmount : 0,
                    taxes: [{
                        taxValue: infoData.tax.taxAmount ? infoData.tax.taxAmount : 0,
                        year: infoData.tax.assessmentYear ? infoData.tax.assessmentYear : "",
                        value: infoData.tax.assessedValue ? Math.round(infoData.tax.assessedValue) : 0
                    }],
                    title: "Estimated Property Taxes"
                }
            }

            updateTaxRecords(taxVal)

            // home card info
            let neighborhoodData = null

            if (infoData.neighborhood !== undefined) {
                if (infoData.neighborhood.name) {
                    neighborhoodData = infoData.neighborhood.name
                }
            }

            let cardInfo = {
                bathrooms: null,
                bedrooms: null,
                livingSquareFeet: null,
                lotSquareFeet: null,
                partialBathrooms: null,
                propertyUse: null,
                subdivision: neighborhoodData,
                yearBuilt: null
            }

            if (propData) {
                cardInfo = {
                    bathrooms: infoData.propertyInfo.bathrooms ? infoData.propertyInfo.bathrooms : null,
                    bedrooms: infoData.propertyInfo.bedrooms ? infoData.propertyInfo.bedrooms : null,
                    livingSquareFeet: infoData.propertyInfo.livingSquareFeet ? infoData.propertyInfo.livingSquareFeet : null,
                    lotSquareFeet: infoData.propertyInfo.lotSquareFeet ? infoData.propertyInfo.lotSquareFeet : null,
                    partialBathrooms: infoData.propertyInfo.partialBathrooms ? infoData.propertyInfo.partialBathrooms : null,
                    propertyUse: infoData.propertyInfo.propertyUse ? infoData.propertyInfo.propertyUse : null,
                    subdivision: neighborhoodData,
                    yearBuilt: infoData.propertyInfo.yearBuilt ? infoData.propertyInfo.yearBuilt : null
                }
            }

            updateHomeCardInfo(cardInfo)

        }
    }, [infoData])

    useEffect(() => {
        if (searchProperty.street !== undefined) {
            updateSearchSkip(false)
        }
    }, [searchProperty])

    const handleSearch = async (data: SearchPropertyI) => {
        if (data) {
            updateSearchProperty(data)
        }
    }

    return {
        handleSearch,
        addHomeInfo,
        propertyData,
        trendingInfo,
        taxInfo,
        homeCardInfo,
        searchData,
        editHomeInfo,
        infoData,
        mortgageInfo,
        granteeName,
        saleDate
    }
}